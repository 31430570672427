import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "getting-the-script"
    }}>{`Getting the script`}</h2>
    <p>{`Get the script from smilee `}<a parentName="p" {...{
        "href": "https://agent.smilee.fi"
      }}>{`agent tool`}</a>{` domain settings`}</p>
    <h2 {...{
      "id": "choose-installation-method"
    }}>{`Choose installation method`}</h2>
    <p>{`Smilee service can be installed by inserting the script to websites sourcecode of by adding it to `}<a parentName="p" {...{
        "href": "https://tagmanager.google.com/"
      }}>{`Google Tag Manager`}</a></p>
    <h2 {...{
      "id": "source-code-installation"
    }}>{`Source code installation`}</h2>
    <p>{`Just add the script provided to source code of your website and the chat will start to work.
We recommend adding the script to the footer of the site to prevent any problems with loading of the script.`}</p>
    <h2 {...{
      "id": "google-tag-manager-installation"
    }}>{`Google Tag Manager installation`}</h2>
    <p>{`In Google Tag Manager add new tag`}</p>
    <p>{`Select type `}<strong parentName="p">{`Custom HTML`}</strong>{` `}<br />{`
Paste received script to HTML field provided`}</p>
    <p>{`Select `}<strong parentName="p">{`All Pages`}</strong>{` as a Firing Trigger if you want to have the chat on all of the Pages`}</p>
    <p><img alt="Chat tag" src={require("../public/images/chat-tag.png")} /></p>
    <p>{`Save and publish your tag and chat will start working on the website :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      